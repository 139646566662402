import config from '@haaretz/l-config';
import fork from '@haaretz/l-fork.macro';
// import timeoutSignalFactory from '@haaretz/s-common-utils/timeoutSignal';
import { hostname as hostnameWithoutSubdomain } from '@haaretz/s-consts';
import getOperationName from '@haaretz/s-graphql-utils/getOperationName';
import getOperationType from '@haaretz/s-graphql-utils/getOperationType';
import parseQuery from '@haaretz/s-graphql-utils/parseQuery';
import switchToDomain from '@haaretz/s-url-utils/switchToDomain';
import hexEnc from 'crypto-js/enc-hex';
import sha256 from 'crypto-js/sha256';

import type { Env, HtzWebsiteExternalURL } from '@haaretz/s-types';
import type { QueryFunctionContext } from '@tanstack/react-query';

const hostname = `www.${hostnameWithoutSubdomain}`;
const gqlServerKey = typeof window === 'undefined' ? 'gqlServerCluster' : 'gqlServer';

const gqlServerUrl = config.get(gqlServerKey);
const gqlServerClusterUrl = config.has('brightspotGqlCluster')
  ? config.get('brightspotGqlCluster')
  : null;

const brightspotUri = config.has('brightspot') ? config.get('brightspot') : null;

const nodeEnv = config.has('nodeEnv') ? config.get('nodeEnv') : null;
const namespace = config.has('namespace') ? config.get('namespace') : null;

const directCMSQueries = [
  'ArticleServerQuery',
  'SectionServerQuery',
  'ImmutablePageDataServerQuery',
  'ImmutablePageElementsServerQuery',
  'SharedPageElementsServerQuery',
  'ClusterServerQuery',
  'LiveBlogItemsServerQuery',
];

const directCMSClusterQueries = [
  'ArticleServerQuery',
  'SectionServerQuery',
  'ClusterServerQuery',
  'LiveBlogItemsServerQuery',
];

const isStorybook = nodeEnv === 'storybook';
const isLocal = nodeEnv?.startsWith('dev');
const isTaskNamespace = namespace?.startsWith('cu-') || false;

export default function fetcherFactory<TData, TVariables>(
  query: string,
  variables?: TVariables,
  headers?: RequestInit['headers'],
  options: Pick<RequestInit, 'cache' | 'next'> = {}
): (context?: Pick<QueryFunctionContext, 'signal'>) => Promise<TData> {
  return async function fetcher(context) {
    try {
      if (options.next?.tags?.length) {
        for (let i = 0; i < options.next.tags.length; i++) {
          const tag = options.next.tags[i];

          if (tag.length > 256) {
            options.next.tags[i] = sha256(tag).toString(hexEnc);
          }
        }
      }

      const document = parseQuery(query);

      const operationName = (document && getOperationName(document)) || '';
      const operationType = (document && getOperationType(document)) || '';

      // const timeoutSignal = typeof window === 'undefined' ? timeoutSignalFactory(10000) : undefined;

      let url = gqlServerUrl.startsWith('https://')
        ? switchToDomain(
            gqlServerUrl as HtzWebsiteExternalURL<Env, 'gql'>,
            fork<Parameters<typeof switchToDomain>[1]>({
              default: 'haaretz.co.il',
              tm: 'themarker.com',
              hdc: 'haaretz.com',
            })
          )
        : gqlServerUrl;

      if (directCMSQueries.includes(operationName) && brightspotUri) {
        let domain = brightspotUri;

        if (typeof window === 'undefined' && !isLocal && !isStorybook && !isTaskNamespace) {
          if (directCMSClusterQueries.includes(operationName) && gqlServerClusterUrl) {
            domain = gqlServerClusterUrl as typeof domain;
          } else {
            // domain = brightspotUri.replace('https:', 'http:') as typeof domain;
            domain = brightspotUri as typeof domain;
          }
        }

        url = `${domain}/graphql/delivery/gql-deliver` as unknown as typeof url;
      }

      const sha256Hash = sha256(query).toString(hexEnc);

      const { signal } = context || {};

      const params = {
        operationName,
        variables: JSON.stringify(variables || {}),
        extensions: JSON.stringify({
          persistedQuery: {
            version: 1,
            sha256Hash,
          },
        }),
      };

      const { host, connection, ...defaultHeaders } = {
        ...(headers || {}),
        'content-type': 'application/json',
        accept: 'application/json',
        hostname,
      } as Record<string, string>;

      const queryParams = new URLSearchParams(params);

      const queryParamsString = queryParams.toString();

      const fullUrl = `${url}?${queryParamsString}`;

      const isValidURL = fullUrl.length < 2000;

      const allQueryParamsIsValid = Array.from(queryParams.values()).every(
        value => value.length <= 256
      );

      if (operationType === 'query' && isValidURL && allQueryParamsIsValid) {
        const persistedResult = await fetch(fullUrl, {
          credentials: 'include',
          method: 'GET',
          headers: defaultHeaders,
          signal, // || timeoutSignal // TODO:: Uncomment to activate timeout signal: ,
          ...options,
        });

        if (persistedResult.status < 400) {
          const persistendData = await persistedResult.json();

          if (
            !persistendData?.errors?.some(
              (error: { message: string }) => error.message === 'PersistedQueryNotFound'
            )
          ) {
            return persistendData.data;
          }
        }
      }
      const result = await fetch(`${url}?operation=${operationName}`, {
        method: 'POST',
        credentials: 'include',
        headers: defaultHeaders,
        body: JSON.stringify({
          query,
          variables: JSON.parse(queryParams.get('variables') || ''),
          operationName,
          extensions: JSON.parse(queryParams.get('extensions') || ''),
        }),
        signal, // || timeoutSignal // TODO:: Uncomment to activate timeout signal: ,
        ...options,
      });

      const json = await result.json();

      if (json.errors) {
        const { message } = json.errors[0] || {};
        throw new Error(message || 'Error…');
      }

      return json.data;
    } catch (error) {
      const document = parseQuery(query);

      const operationName = (document && getOperationName(document)) || '';

      console.error(
        `GraphQL fetcher error: ${
          (error as Error).message
        }, operationName: ${operationName}, variables: ${JSON.stringify(variables)}`
      );

      throw error;
    }
  };
}
